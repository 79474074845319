<template>
  <div>
    <div class="heng">

      <div class="butclss">
        <h3 class="digitization">专注央国企管理软件和解决方案</h3>
        <div class="content_Img">
          在数字经济，数字中国背景下，面向央国企提供数字化转型技术咨询、软件产品及软件整体解决方案。用新一代AI技术为支撑，创新工作模式，实现业务突破，完成信息化往数字化的转变，从成本中心向核心价值中心的转变。
        </div>

        <div class="bot_img">
          <div class="lianx">
            <button class="butt-on" type="button" @click="pageHref">
              联系我们
            </button>
          </div>
          <div class="genduo">
            <button class="butt-two" type="button" @click="viewMore">
              查看更多
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="img-box">
      <div class="img-content">
        <!-- <img src=".././assets/img/group1.png" style="cursor:pointer;" @click="pageHref"> -->
        <div v-for="(item,index) in group1List" :key="index" class="group1List-div">
          <div class="blod-font">{{ item.title1 }}</div>
          <div class="normal-div">
            <div class="normal-font">{{ item.title2 }}</div>
            <div class="normal-font">{{ item.title3 }}</div>
          </div>
          <div class="blue-font" @click="pageHref">免费获取解决方案>></div>
        </div>
      </div>
    </div>
    <!-- <div class="homeimg">
       <div class="imgq">
        <div>
      <img src=".././assets/img/groupsix.png">
        <img src=".././assets/img/groupseven.png">
          </div>
      <div>
        <img src=".././assets/img/groupeight.png">
        <img src=".././assets/img/groupnine.png">
      </div>
        </div>
      </div> -->

    <div class="service_box">
      <h4>金烽科技的核心能力</h4>
      <div class="service_content">
        <div class="group2-1">
            <div class="blod-font font1">技术咨询及服务能力 <span class="o-line"></span></div>
            <div class="flex-item">
              <div class="font1"><span class="o-dot"></span>软件技术咨询服务 </div>
            </div>
            <div class="flex-item">
              <div class="font1"><span class="o-dot"></span>软件平台建设服务</div>
            </div>
            <div class="flex-item">
              <div class="font1"><span class="o-dot"></span>软件平台运维升级服务</div>
            </div>
        </div>
        <div class="group2-2">
          <div class="blod-font font1">金烽核心软件产品 <span class="o-line"></span></div>
          <div class="flex-item">
              <div class="font1"><span class="o-dot"></span>大数据平台(保障/应急/能源/旅游) </div>
            </div>
            <div class="flex-item">
              <div class="font1"><span class="o-dot"></span>央国企运营管理(协同/融资/营销/资产)</div>
            </div>
            <div class="flex-item">
              <div class="font1"><span class="o-dot"></span>智慧交通(航运监管/低空空域监管)</div>
            </div>
        </div>
        <div class="group2-3">
          <div class="blod-font font1">数字孪生服务 <span class="o-line"></span></div>
          <div class="flex-item">
              <div class="font1"><span class="o-dot"></span>三维建模</div>
              <div class="font1"><span class="o-dot"></span>城市级可视化呈现</div>
            </div>
            <div class="flex-item">
              <div class="font1"><span class="o-dot"></span>区域级可视化呈现</div>
              <div class="font1"><span class="o-dot"></span>场馆级可视化呈现</div>
            </div>
        </div>
      </div>
    </div>

    <div class="business_box">
      <h4>金烽软件为行业痛点提供解决方案</h4>
      <div class="business_content">
        <img src=".././assets/img/解决方案.png">
        <div class="content_one">
          <div class="title">数字通信</div>
          <div class="nrong">
            <div class="font_size margin_bot">通信质量保障</div>
            <div class="font_size margin_bot">能耗用能提效</div>
            <div class="font_size"> 用户行业分析......</div>
          </div>
        </div>

        <div class="content_two">
          <div class="title">运营管理</div>
          <div class="nrong">
            <div class="font_size margin_bot">协同管理 资金管理 </div>
            <div class="font_size margin_bot">融资管理 债务管理 </div>
            <div class="font_size">项目管理 资产管理</div>
          </div>
        </div>

        <div class="content_there">
          <div class="title">智慧交通</div>
          <div class="nrong">
            <div class="font_size margin_bot">航运指挥 航运调度</div>
            <div class="font_size margin_bot">低空空域监管 空域应急处置</div>
            <div class="font_size">空域监管可视化 数字孪生方案</div>
          </div>
        </div>

      </div>
    </div>

    <div class="select_box">
      <h4>300+标杆企业共同选择</h4>
      <div class="select_content">
        <img v-for="i in 24" :key="i" :src="require('.././assets/img/' + i + '.png')">
      </div>
    </div>

    <div class="certificate_box">
      <h4>多重执业合作资质</h4>
      <div class="certificate_content">
        <img v-for="i in 8" :key="i" :src="require('.././assets/img/d' + i + '.png')">

      </div>
      <div class="imgy">
        <div class="journey">
          <input type="text" value="开起数字化旅程" disabled>
          <bottom>立即体验</bottom>
        </div>
      </div>
    </div>

    <!-- <div>
      <div class="imgqw">
     <img src=".././assets/img/groupeleven.png" >
       </div>
     </div> -->

    <!-- <div class="imge">
     <p >数字化解决方案</p>
       <div  class="imgeone">
        <img src=".././assets/img/groupnum.png">
        <img src=".././assets/img/groupfourteen.png">
        <img src=".././assets/img/groupthirteen.png" >
        <img src=".././assets/img/groupfifteen.png" >
      </div>
     </div> -->

    <!-- <div>
     <div class="imgr">
      <img src=".././assets/img/groupseventeen.png" >
     </div>
      </div> -->

    <!-- <div>
         <div class="imgt">
           <img src=".././assets/img/groupsixteen.png" >
         </div>
       </div> -->

  </div>
</template>

<script>
export default {
  data () {
      return {
        group1List: [
          {
            title1: '软件产品提供商',
            title2: '专注大数据、运营管理及交通运输行业',
            title3:'提供专业的软件产品及解决方案'
          },
          {
            title1: '100+软件技术服务团队',
            title2: '以新技术、高效率引领',
            title3:'用研发赋能,助力央国企创新发展'
          },
          {
            title1: '300+央国企软件合作案例',
            title2: '7年技术经验沉淀,锻造金烽优秀团队',
            title3:'100+央国企服务案例,汇聚多行业业务经验'
          }
        ]
        }
      },
  created() {
    console.log(this.$route)
  },
  methods: {
    pageHref() {
      this.$router.push({
        path: '/tech/about',
        query: {
          vs: 1
        }
      })
    },
    viewMore() {
      this.$router.push('/tech/case')
    }
  }
}
</script>

<style lang="scss" scoped>
.heng {
  background-image: url(".././assets/img/banner.png");
  object-fit: contain;
  width: 100%;
  height: 575px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.butclss {
  padding-left: 324px;
  padding-top: 190px;

  .digitization {
    color: #ffffff;
    font-size: 71px;
  }

  .content_Img {
    margin-top: 15px;
    margin-bottom: 44px;
    font-weight: 400;
    color: #d0d4e1;
    font-size: 18px;
    width: 600px;
    line-height: 28px;
  }

  .bot_img {
    display: flex;
  }
}

.lianx {
  button {
    width: 180px;
    height: 50px;
    background: #ff892e;
    border: 1px solid #ff892e;
    opacity: 1;
    border-radius: 4px;
  }
}

.butt-on {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

.genduo {
  margin-left: 11px;

  button {
    width: 110px;
    height: 50px;
    background: rgba(255, 137, 46, 0);
    border: 1px solid #ffffff;
    opacity: 1;
    border-radius: 4px;
  }
}

.butt-two {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

.homeimg {
  width: 100%;
  height: 420px;
  position: relative;
  display: flex;
  justify-content: center;
}

.imgq {
  top: -50px;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.imgqw {
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 1412px;
    height: 761px;
  }
}

.imge {
  text-align: center;
  margin-top: 18px;

  p {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    margin-bottom: 77px;
  }

  .imgeone {
    width: 1423px;
    margin: 0 auto;

    img {
      margin: 0 13px 36px 13px;
      width: 680px;
    }
  }
}

.imgr {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 1399px;
  }
}

.imgt {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 131px;

  img {
    width: 1399px;
  }
}

.imgy {
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.journey {
  position: relative;

  input {
    width: 500px;
    height: 72px;
    border: 1px solid #ff892e;
    opacity: 1;
    border-radius: 4px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    text-align: center;
    padding-right: 236px;
  }

  bottom {
    top: 0;
    right: 0;
    position: absolute;
    height: 72px;
    width: 200px;
    border-radius: 4px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    background: #ff892e;
    padding-top: 21px;
  }
}

.img-box {
  width: 1400px;
  margin: auto;
  height: 270px;
  .img-content {
    background: url(".././assets/img/group1.png") no-repeat;
    background-size: 102% 102%;
    background-position: center;
    width: 1400px;
    height: 270px;
    margin-top: -37px;
    position: relative;
  }
  .group1List-div{
    width: 33%;
    padding: 17px 10px 20px 160px;
    display: inline-block;
    margin-top: 20px;
  }
}

.service_box {
  width: 1424px;
  padding: 0 15px;
  margin: auto;

  h4 {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    margin: 50px 0 50px;
  }

  .service_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .group2-1,.group2-2,.group2-3{
      padding: 250px 29px 20px 49px;
    }
    .flex-item{
      display: flex;
      justify-content: space-between;
      line-height: 42px;
      font-weight: 400;
      font-size: 20px;
      color: #536087;
    }
    .o-dot{
      width: 6px;
      height: 6px;
      background: #FF892E;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }
    .group2-1{
      width: 449px;
      height: 461px;
      background: url(.././assets/img/group2-1.png) no-repeat;
      background-size: 100% 100%;
    }
    .group2-2{
      width: 449px;
      height: 461px;
      background: url(.././assets/img/group2-2.png) no-repeat;
      background-size: 100% 100%;
    }
    .group2-3{
      width: 449px;
      height: 461px;
      background: url(.././assets/img/group2-3.png) no-repeat;
      background-size: 100% 100%;
    }
    .font1{
      position: relative;
      .o-line{
        width: 28px;
        height: 5px;
        background: #FF892E;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 35px;
      }
    }
  }
}

.business_box {
  width: 1424px;
  margin: auto;
  padding: 0 15px;

  h4 {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    margin: 50px 0 50px;
  }

  .business_content {
    height: 624px;
    width: 100%;
    display: flex;
    position: relative;

    .title {
      margin-bottom: 22px;
      font-size: 28px;
      font-weight: bold;
      color: #ffffff;
    }

    .font_size {
      font-weight: 400;
      color: #ffffff;
      font-size: 20px;
    }

    .margin_bot {
      margin-bottom: 10px;
    }

    .nrong {
      font-size: 16px;
      color: #ffffff;
    }

    .content_one {
      position: absolute;
      bottom: 33px;
      left: 46px;
    }

    .content_two {
      position: absolute;
      bottom: 33px;
      left: 516px;
    }

    .content_there {
      position: absolute;
      bottom: 33px;
      right: 150px;
    }
  }
}

.select_box {
  width: 1424px;
  margin: auto;
  padding: 0 15px;

  h4 {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    margin: 50px 0 50px;
  }

  .select_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    // row-gap: 15px;
    img {
      width: 160px;
    }
  }
}

.certificate_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(".././assets/img/bg.png") no-repeat;
  background-size: cover;
  margin-top: 20px;

  h4 {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    margin: 30px 0 50px;
  }

  .certificate_content {
    width: 1424px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    // row-gap: 10px;
    img {
      width: 330px;
    }
  }
}

.blod-font{
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  margin-bottom: 20px;
}
.normal-div{
  height: 60px;
}
.normal-font{
  font-weight: 400;
  font-size: 17px;
  color: #536087;
  line-height: 24px;

}
.blue-font{
  font-weight: 400;
  font-size: 16px;
  color: #003CE2;
  line-height: 24px;
  cursor: pointer;
  margin-top: 30px;
}
.mr20{
  margin-right: 20px;
}
</style>
